import React, { Component } from "react";

class NavBar extends Component {
  shoot() {
    var element = document.getElementById("nav");
    element.classList.toggle("expand");
    var menu = document.querySelector(".ugb-nav-icon");
    menu.classList.toggle("active");
  }

  render() {
    return (
      <div id="navigation">
        <div className="ugb-nav-icon" onClick={this.shoot}>
          <div>
            <span class="b1">
              <span class="nline"></span>
            </span>
            <span class="b2">
              <span class="nline"></span>
            </span>
          </div>
        </div>
        <div id="nav">
          <ul className="navlist">
            <li className="navLink">
              <a href="#Homelink" id="Home" onClick={this.shoot}>
                Home
              </a>
            </li>
            <li className="navLink">
              <a href="#Aboutlink" id="About" onClick={this.shoot}>
                About
              </a>
            </li>
            <li className="navLink">
              <a href="#Barberslink" id="Barbers" onClick={this.shoot}>
                Barbers
              </a>
            </li>
            <li className="navLink">
              <a href="#Serviceslink" id="Services" onClick={this.shoot}>
                Services
              </a>
            </li>
            <li className="navLink">
              <a href="#Contactlink" id="Contact" onClick={this.shoot}>
                Hours & Location
              </a>
            </li>
            <li className="navLink" onClick={this.shoot}>
              <a href="tel:519-745-6481">Book Appointment</a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default NavBar;
