import React, { Component } from "react";
import "./App.css";
import Logo from "./img/walperlogo.svg";
import Scizzor from "./img/scizzors.svg";
import Line from "./img/line.svg";
import NavBar from "./components/navigation";

class Projects extends Component {
  render() {
    return (
      <div className="Body">
        <NavBar></NavBar>
        <div
          id="Homelink"
          onClick="window.location.hash='Home';"
          className="Section Home"
        >
          <img className="mainLogo" src={Logo}></img>
          <h1>Continuing 126 years of service in Kitchener.</h1>
        </div>
        <div
          id="Aboutlink"
          onclick="window.location.hash='About';"
          className="Section"
        >
          <div className="AboutText">
            <img className="icon" src={Scizzor}></img>
            <h2>About the shop</h2>
            <img className="line" src={Line}></img>
            <p className="intro">
              The Walper barbershop would like to thank all of the people that
              have come through our doors over the past 126 years. We have been
              honoured to not only serve your barbering needs but also to share
              all of life’s moments from the joys of childbirth to the sorrows
              of losing your loved ones and all the other special and ordinary
              times in between.
            </p>
          </div>
        </div>
        <div
          id="Barberslink"
          onclick="window.location.hash='Barbers';"
          className="Section Barbers"
        >
          <div className="barbertitle">
            <h2>The Barbers</h2>
            <img className="line" src={Line}></img>
          </div>
          <div className="barberpros">
            <div className="profile">
              <div className="proImg Robert"></div>
              <h2>Robert</h2>
              <p className="proText">
                Working at the Walper for over 20 years. Robert is an
                experienced barber offering Mens Haircuts, beard trimming and
                scalp massages.
              </p>
            </div>
            <div className="profile">
              <div className="proImg Sandra"></div>
              <h2>Sandra</h2>
              <p className="proText">
                With over 35 years of Barbering. Sandra has been cutting hair at
                the Walper for the last 26 years. Offering Mens Haircuts, beard
                trimming and scalp massages.
              </p>
            </div>
          </div>
          <p className="Creditp">
            Photos for Robert and Sandra by Nick Stanley
          </p>
        </div>
        <div
          id="Serviceslink"
          onclick="window.location.hash='Services';"
          className="Section Services"
        >
          <div className="ServContent">
            <h2 className="ServHeader">Our Services</h2>
            <p className="ServText">
              We ask that our clients call into the barbershop phone to book any
              appointments, walk-ins accepted by chance.
            </p>
            <p className="ServText Button">
              <a className="BookButton" href="tel:519-745-6481">
                Book Appointment
              </a>
            </p>
          </div>
        </div>
        <div
          id="Contactlink"
          onclick="window.location.hash='Contact';"
          className="CSection"
        >
          <div className="CContent">
            <h2>Hours & Location</h2>
            <img className="line" src={Line}></img>
            <p className="conInfo">
              We are open for appointments made by phone only from Tuesday - Friday ( 8 to
              5 ) and Saturday ( 8 to 3 ).
            </p>
            <p className="conInfo">
              Please feel free to contact at{" "}
              <a className="conLink" href="tel:519-745-6481">
                (519) 745-6481.
              </a>
            </p>
          </div>
          <iframe
            className="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2896.530252584466!2d-80.49771744899725!3d43.449525873492746!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882bf4f29a552349%3A0xb83dcb3817f479fc!2sThe%20Walper%20Barbers!5e0!3m2!1sen!2sca!4v1584912938748!5m2!1sen!2sca"
            width="450"
            height="450"
            frameborder="0"
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0"
          ></iframe>
        </div>
        <div className="footer">
          <p className="footext">
            119 Joseph St, Kitchener, ON N2G 1J3<br></br>
            For more information follow us on facebook or feel free to contact
            us at <a href="tel:519-745-6481">(519) 745-6481.</a>
          </p>
          <p className="copy">© 2022 | Walper Barbers</p>
        </div>
      </div>
    );
  }
}
export default Projects;
